<template>
  <v-app>
    <Navbar v-if="$route.name != 'login'"></Navbar>
    <sidebarChat
        v-if="$route.name != 'login' && userIsLoggedIn"
    ></sidebarChat>

    <!--<SelectCycle v-if="!getActiveCycle && userIsLoggedIn "></SelectCycle>-->

    <v-btn fab small class="back-to-top" v-if="scrollFabIsVisible" @click="$vuetify.goTo(0)">
      <v-icon>keyboard_arrow_up</v-icon>
    </v-btn>
    <!--<div id="cycle" class="back-to-top" v-show="userIsLoggedIn && userHasRole('customer')">-->
    <!--<ActiveCycleDD></ActiveCycleDD>-->
    <!--</div>-->
    <v-card class="elevation-0 main-container" v-if="renderComponent" :style="'min-height:'+height+'px;'">
      <!--:style="{'min-height:100%':$route.name == 'login'}">-->

      <PayableFactorAlert
          v-if="userIsLoggedIn && $router.currentRoute.name!='clientSingleFactor'">
      </PayableFactorAlert>

      <v-fade-transition mode="out-in">
        <router-view></router-view>
      </v-fade-transition>


    </v-card>
    <v-fade-transition>
      <!--      <div v-if="loading">-->
      <!--        <v-dialog v-model="localTrue" max-width="500" persistent>-->
      <!--            <v-card>-->
      <!--                <v-card-text class="pt-5">-->
      <!--                    <v-progress-linear-->
      <!--                            active-->
      <!--                            indeterminate-->
      <!--                            query-->
      <!--                            rounded-->
      <!--                            stream-->
      <!--                    ></v-progress-linear>-->
      <!--                </v-card-text>-->
      <!--            </v-card>-->
      <!--        </v-dialog>-->
      <!--      </div>-->
    </v-fade-transition>

    <clientDashboard @closed="showAddSessionDialog=false" v-if="showAddSessionDialog"
                     :sessionRequest="true"></clientDashboard>
    <!--<v-container >-->
    <!--<v-row>-->
    <!--<v-col>-->
    <!--&lt;!&ndash;<AlertsComponent></AlertsComponent>&ndash;&gt;-->
    <!--</v-col>-->


    <!--</v-row>-->
    <!--</v-container>-->

    <myfooter :isSimiaroom="true" v-if="$route.name != 'login'"></myfooter>
    <!--<Snackbar :snackbar="snackbar"></Snackbar>-->
  </v-app>
</template>

<script>
/*eslint-disable*/
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue';
import AlertsComponent from '@/components/AlertsComponent.vue';
import PayableFactorAlert from '@/components/PayableFactorAlert'
import Myfooter from '@/components/Myfooter';
// import Snackbar from "../../components/Snackbar";
import {mapGetters} from 'vuex'
// import ActiveCycleDD from "../../components/ActiveCycleDD";
import SelectCycle from "../../components/SelectCycle";
import clientDashboard from '@/components/dashboards/clientDashboard';
import {EventBus} from "@/event-bus";
import sidebarChat from '@/components/chat/SidebarComponent.vue';

export default {
  name: 'mainlayout',
  components: {
    PayableFactorAlert,
    clientDashboard,
    Navbar,
    Myfooter,
    // Snackbar,
    // ActiveCycleDD,
    SelectCycle,
    AlertsComponent,
    sidebarChat

  },
  created() {
    this.$store.dispatch('checkLogin').then(() => {
      this.$store.dispatch('getCart');
    }).finally(() => {
      this.$nextTick().then(() => {
        this.$store.commit('setLoading', false);
      })
    });
    // EventBus.$on('setLoading', this.setLoading);
    EventBus.$on('showAddSessionDialog', this.openSessionRequestDialog);
    window.addEventListener('scroll', this.handleScroll);
    if (this.userIsLoggedIn) {
      if (this.getUserInfo.role == 'customer') {
        // this.checkActiveCycle();
      }
    }

    if (window.origin.includes('simiaroom')) {
      this.isSimiaroom = true;
    } else {
      this.isSimiaroom = false;
    }
  },
  beforeDestroy() {
    // EventBus.$off('setLoading', this.setLoading);
  },

  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.height = window.innerHeight;
  },
  data() {
    return {
      localTrue: true,
      showAddSessionDialog: false,
      prevHeight: 0,
      height: window.innerHeight,
      // showSelectActiveCycle: false,
      lang: "",
      path: this.$route.name,
      drawer: true,
      // pageIsScrolled: false,
      scrollFabIsVisible: false,
      renderComponent: true,
      fromPreorder: localStorage.getItem('fromPreorder'),
      isSimiaroom: false
    };
  },
  computed: {
    ...mapGetters([
      'getPayableFactor',
      'snackbar',
      'userIsLoggedIn',
      'userHasRole',
      // 'getActiveCycle',
      'getUserInfo',
      'getMinHeight',
    ]),

  },
  watch: {

    // getActiveCycle(newValue) {
    //     console.log(newValue)
    //     this.renderComponent = false;
    //
    //     this.$nextTick(() => {
    //         this.renderComponent = true;
    //     })
    //
    // },
    // 'getUserInfo.state'(newValue) {
    //     console.log(newValue)
    //     this.renderComponent = false;
    //
    //     this.$nextTick(() => {
    //         this.renderComponent = true;
    //     })
    //
    // },
  },
  methods: {
    setLoading(loading) {
      // console.log('got here');
      this.$store.commit('setLoading', loading);
    },
    openSessionRequestDialog() {
      this.showAddSessionDialog = true;
    },
    // checkActiveCycle() {
    //     this.cycles = this.getUserInfo.state.cycles;
    //     if (this.cycles.length > 1 && this.activeCycle == null) {
    //         this.showSelectActiveCycle = true;
    //     } else if (this.cycles.length == 1) {
    //         this.$store.commit('setActiveCycle', this.cycles[0]);
    //     } else if (this.cycles.length == 0) {
    //         this.$store.commit('setActiveCycle', null);
    //     }
    // },
    handleScroll() {
      if (window.scrollY > 100 && !this.scrollFabIsVisible) {

        this.scrollFabIsVisible = true;

      } else if (window.scrollY <= 100 && this.scrollFabIsVisible) {

        this.scrollFabIsVisible = false;

      }

    }
  }

}
</script>

<style>
.back-to-top {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 2;
}

#cycle.back-to-top {
  position: fixed;
  right: auto;
  left: 75px;
  bottom: 75px;
}

</style>