<template>
    <v-dialog v-model="showSelectActiveCycle" persistent max-width="500">
        <v-card align="center" class="d-flex flex-column align-center justify-center py-3">
            <v-card-title class="font-weight-bold">انتخاب سایکل فعال</v-card-title>
            <v-card-text class="py-0">
                <v-container class="pa-1">
                    <v-item-group
                            v-model="selectedCycle"
                            @change="selectCycle(selectedCycle)"
                    >
                        <v-row justify="center">
                            <v-col
                                    v-if="newCycle"
                                    cols="12"
                                    md="4"
                                    sm="4">
                                <v-item v-slot:default="{ active, toggle }">
                                    <v-card height="100" width="100" @click="toggle"
                                            class="d-flex justify-center align-center">
                                        <v-btn x-small fab>
                                            <v-icon color="secondary" large style="margin-top: 1px">
                                                {{ active ? 'fas fa-plus-circle' : 'mdi-plus' }}
                                            </v-icon>
                                        </v-btn>
                                    </v-card>
                                </v-item>
                                <div class="pt-3 flex-column d-flex">
                                    <small class="font-weight-bold">سایکل جدید</small>
                                </div>
                            </v-col>
                            <v-col
                                    v-for="cycle in cycles"
                                    :key="cycle.id"
                                    cols="12"
                                    md="4"
                                    sm="4"
                            >
                                <v-item v-slot:default="{ active, toggle }">
                                    <v-card height="100" width="100" @click="toggle">

                                        <v-img
                                                :src="require('./../assets/avatar-default.png')"
                                                height="100"
                                                width="100"
                                                class="text-right pa-2"
                                        >
                                            <v-btn x-small fab>
                                                <v-icon color="secondary" large style="margin-top: 1px">
                                                    {{ active ? 'mdi-checkbox-marked-circle' :
                                                    'mdi-checkbox-blank-circle-outline' }}
                                                </v-icon>
                                            </v-btn>
                                        </v-img>
                                    </v-card>
                                </v-item>

                                <div class="pt-3 flex-column d-flex">
                                    <!--<small class="font-weight-bold" v-if="cycle.counselor">-->
                                        <!--{{`${cycle.counselor.firstname} ${cycle.counselor.lastname}`}}-->
                                    <!--</small>-->
                                    <small>{{cycle.heading}}</small>
                                </div>
                            </v-col>
                        </v-row>
                    </v-item-group>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn dark color="secondary darken-1" @click="confirmActiveCycle">تایید</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    /*eslint-disable*/
    import {EventBus} from "@/event-bus";

    export default {
        props: ['newCycle', 'preorder'],
        data() {
            return {
                cycles: null,
                showSelectActiveCycle: false,
                selectedCycle: null,
                query: null
            }
        },
        computed: {
            profile() {
                return this.$store.getters.getUserInfo
            },
            activeCycle() {
                return this.$store.getters.getActiveCycle
            }
        },
        created() {
            EventBus.$on('userLoggedIn', this.checkActiveCycle);
            this.query = this.$router.query;
            this.checkActiveCycle();
        },
        beforeDestroy() {
            EventBus.$off('userLoggedIn', this.checkActiveCycle);
        },
        methods: {
            checkActiveCycle() {

                if (this.profile != null) {


                    if (this.profile.role == 'customer') {
                        this.cycles = this.profile.state.cycles;
                        if (this.cycles.length > 1) {
                            this.showSelectActiveCycle = true;
                        } else if (this.cycles.length == 1) {
                            if (this.preorder) {
                                this.showSelectActiveCycle = true;
                            } else {
                                this.$store.commit('setActiveCycle', this.cycles[0]);
                            }
                        } else if (this.cycles.length == 0) {
                            this.$store.commit('setActiveCycle', null);
                        }
                    }
                }
            },
            selectCycle(cycle) {
                console.log(cycle);
                this.selectedCycle = cycle;
                console.log(this.selectedCycle)
            },
            confirmActiveCycle() {
                if (this.preorder) {
                    if (this.selectedCycle != 0) {
                        this.$store.commit('setActiveCycle', this.cycles[this.selectedCycle - 1]);
                        console.log(this.cycles[this.selectedCycle - 1])
                    }
                    this.sendOrder();
                } else {
                    this.$store.commit('setActiveCycle', this.cycles[this.selectedCycle])
                }
                this.showSelectActiveCycle = false;
            },
            sendOrder() {
                console.log('sendorder')
                let products = [];
                let data = {};
                this.$store.getters.getCartItems.forEach((item) => {
                    products.push(
                        {
                            'id': item._id,
                            'quantity': item.qty
                        }
                    );
                });
                data.products = products;
                data.voucher = "";
                data.totalPrice = this.$store.getters.getSumPrice;
                data.finalPrice = this.$store.getters.getSumPrice;
                data.query = this.$route.query;

                if (this.selectedCycle == 0) {
                    // console.log('-----', this.cycles[this.selectedCycle])
                    data.newCycle = true;
                }

                console.log("--------------------'I AM HERE'--------------------");
                console.log('I AM HERE');
                console.log("--------------------'I AM HERE'--------------------");
                this.$store.dispatch('sendOrder', data).then((data) => {

                    window.location = data.data;

                    //
                    // const success_code = data.data.substring(data.data.search("=") + 1);
                    // this.$route.from == {name: 'prorder'}
                    // // this.$router.push({ name: 'assignconsult', query: {successful_payment: success_code} });
                    // this.$router.push({
                    //     path: '/fa/panel/assignconsult',
                    //     query: {successful_payment: success_code},
                    //
                    // });
                    // console.log('here')
                    // this.$store.commit('emptyCart');
                    // this.$store.dispatch('getUserInfo');
                })
            }
        }

    }
</script>

<style>

</style>