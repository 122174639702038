<template>


  <v-container fluid class="px-0 mx-0" :class="{'py-0 my-0':!breadcrumb}">
    <v-container class="lighten-5 px-0 pt-0 mx-0" v-if="products.length>0">
      <v-row no-gutters justify="right">
        <v-col
            v-for="(item, index) in products"
            :key="`product-${index}`"
            cols="12"
            lg="4"
            md="4"
            sm="6"
        >

          <v-card
              hover
              style="cursor:default;"
              justify="right"
              class="elevation-25 ma-2"
              :class="{'disabled':getCartType != null && item.currency != getCartType}"
          >
            <v-card-title class="justify-center pt-5 pb-5 gray--text display-6 font-weight-bold">
              <template v-if="item.currency != 'dollar'">
                <span v-if="! $vuetify.rtl">{{ $t(resolveCurrency(item.currency)) }}</span>
                <span class="mx-1">{{ (Number(item.price).toLocaleString()) }}</span>
                <span v-if="$vuetify.rtl">{{ $t(resolveCurrency(item.currency)) }}</span>
              </template>
              <template v-else>
                <span v-if="! $vuetify.rtl">{{ $t(resolveCurrency(item.currency)) }}</span>
                <span class="ma-1">{{ item.price }}</span>
                <span v-if="$vuetify.rtl">{{ $t(resolveCurrency(item.currency)) }}</span>
              </template>

            </v-card-title>
            <v-card-subtitle>{{ $vuetify.rtl ? item.title : item.en_title }}</v-card-subtitle>
            <!--<v-card-title class="justify-center">-->
            <!--<img class="m-auto" src="../../assets/origami.svg"/>-->
            <!--</v-card-title>-->
            <v-alert class="mx-5 my-2 elevation-0 font-weight-bold label outlined-primary" dense>

              <template v-if="$vuetify.rtl">
                {{ (Number(item.single_session_price).toLocaleString()) }} {{$t(resolveCurrency(item.currency))}}
              </template>
              <template v-else>
                {{$t(resolveCurrency(item.currency))}}{{ (Number(item.single_session_price).toLocaleString()) }}
              </template>
              / <span>{{ $t('$vuetify.productGroup.priceSession') }}</span>
            </v-alert>

            <v-card-actions class="justify-center py-5">
              <v-btn rounded block class="px-3 elevation-0"
                     :color="getCartType != null && item.currency !== getCartType ? 'primary':'warning'"
                     @click="addToCart(item)"
              >
                {{ $t('$vuetify.addToBasket') }}
              </v-btn>
            </v-card-actions>

          </v-card>

        </v-col>
      </v-row>
      <v-row>
        <v-pagination
            v-model="page"
            :length="total"
        >
        </v-pagination>
      </v-row>
    </v-container>
    <v-container v-else>
      <p class="my-10 text-center">
                    <span v-if="!loading">
                      {{ $t('$vuetify.orderProductPage.noPackage') }}
                    </span>
        <span v-else>
                      {{ $t('$vuetify.orderProductPage.loading') }}
                    </span>

      </p>

    </v-container>
  </v-container>
</template>

<script>
/*eslint-disable*/
import {mapGetters} from 'vuex';
import {EventBus} from "@/event-bus";
import {resolveCurrency} from "@/utilities";

export default {
  name: 'pricing',
  props: {
    type: {
      default: 'session'
    },
    pushToCart: {
      default: true
    },
    showBreadCrumb: {
      default: true
    }
  },
  data() {
    return {
      loading: false,
      show: false,
      activeTab: null,
      page: 1,
      total: 1,
      products: [],
      isHovered: false,
      breadcrumb: true,
      items: [
        {
          text: 'داشبورد',
          disabled: false,
          to: '/fa/panel/dashboard',
        },
        {
          text: 'خرید بسته',
          disabled: true,
          to: '/fa/panel/order',
        }
      ]
    }
  },
  computed: {

    ...mapGetters(['getCartType', 'getUserInfo']),
    productList() {
      return this.$store.getters.getProductList;

    }
  },
  created() {
    setTimeout(() => {
      this.show = true;
    }, 3000);
    this.breadcrumb = this.showBreadCrumb !== "false";
    this.getProductList();
  },
  mounted() {

  },
  methods: {

    sendWebengageAddToCart(data) {
      window.webengage.track('add_to_cart', data);
    },
    getProductList() {
      EventBus.$emit('setLoading', true);
      this.loading = true;
      let payload = {
        type: this.type
      };
      this.$store.dispatch('searchProducts', {page: this.page, payload}).then((data) => {
        console.log(data)
        this.products = data.packages;
        this.total = data.pagination.total_pages;
      }).finally(() => {
        EventBus.$emit('setLoading', false);
        this.$emit('hasPackages');
        this.loading = false;
      })
    },
    addToCart(item) {
      if (this.getCartType != null && item.currency !== this.getCartType) {

        this.$store.commit('setSnackbar', {
          show: true,
          type: 'error',
          message: 'ارز آیتم موجود در کارت شما با این محصول مطابقت ندارد'
        });
      } else {
        EventBus.$emit('setLoading', true);
        this.$store.dispatch('addToCart', item).then(() => {

          let product = {
            'name': item.title,
            'id': item.id,
            'price': item.price,
            'brand': 'Simiaroom',
            'category': 'Counseling',
            'quantity': 1
          };

          this.sendWebengageAddToCart(product);

          this.$gtm.trackEvent({
            'event': 'eec.addToCart',
            'ecommerce': {
              'add': {
                'products': [{
                  'name': item.title,
                  'id': item.id,
                  'price': item.price,
                  'brand': 'Simiaroom',
                  'category': 'Counseling',
                  // 'variant': 'Gray',
                  'quantity': 1
                }]
              }
            }
          });

          // this.$store.dispatch('getCart');
          this.$emit('closeWizard');
          this.$router.push({name: 'clientCart', params: {lang: this.$route.params.lang}});
          EventBus.$emit('setLoading', false);
        }).finally(() => {
          EventBus.$emit('setLoading', false);
        });
      }
    },
    resolveCurrency(input) {
      return resolveCurrency(input);
      // return input == 'rial' ? 'تومان' : 'دلار';
    }
  },
  filters: {},
  watch: {
    page() {
      this.getProductList();
    }
  }
}

</script>


<style scoped>

</style>
