<template>
  <v-container fluid>
    <!--        <v-tour name="firstStep" :steps="tourSteps.first"-->
    <!--                :options="myOptions"-->
    <!--        ></v-tour>-->
    <!--        <v-tour name="secondStep" :steps="tourSteps.second"-->
    <!--                :options="myOptions"-->
    <!--        ></v-tour>-->
    <!--        <v-tour name="thirdStep" :steps="tourSteps.third"-->
    <!--                :options="myOptions"-->
    <!--        ></v-tour>-->
    <v-dialog max-width="1100" v-if="loaded" v-model="wizard" scrollable>
      <v-card class="elevation-0">
        <v-card-text class="pa-0 ma-0">
          <v-stepper v-model="step">
            <v-stepper-header class="primary main-separator">
              <v-stepper-step :editable="step > 1" :complete="step > 1" step="1">
                {{ $t('$vuetify.clientStepper.header[0]') }}
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :editable="step > 2 || !profile.state.requireSign" :complete="step > 2"
                              step="2">
                {{ $t('$vuetify.clientStepper.header[1]') }}
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="step > 3" :editable="step > 3 || !profile.state.requireBuy "
                              step="3">
                {{ $t('$vuetify.clientStepper.header[2]') }}
              </v-stepper-step>
              <v-divider></v-divider>
              <!--<v-stepper-step :complete="step > 4" step="4">انتخاب مشاور</v-stepper-step>-->
              <!--<v-divider></v-divider>-->

              <v-stepper-step :complete="step > 4" :editable="step > 4" step="4">
                {{ $t('$vuetify.clientStepper.header[3]') }}

              </v-stepper-step>

            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">

                <v-card class="elevation-0"

                >
                  <v-alert color="purple-light" class="white--text">
                    {{ $t('$vuetify.clientStepper.basicInformation.title') }}
                  </v-alert>
                  <v-card-text>
                    <v-form ref="profileForm" data-v-step="0">
                      <v-row>


                        <v-col cols="12" sm="6" md="4">
                          <v-text-field v-model="profileInfo.name" outlined
                                        :label="$t('$vuetify.clientStepper.basicInformation.name')"
                                        :rules="requiredRules"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field v-model="profileInfo.family" outlined
                                        :rules="requiredRules"
                                        :label="$t('$vuetify.clientStepper.basicInformation.family')"
                          >

                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" style="position: relative">

                          <div style="text-align: right!important;position:absolute;top:-10px;font-weight: normal;">
                            {{ $t('$vuetify.clientStepper.basicInformation.phone') }}
                          </div>
                          <v-text-field
                              v-model="profileInfo.mobile"
                              outlined
                              prepend-icon="phone"
                              type="tel"
                              name="tel"
                              placeholder="example : +98 123 123 1234"
                              :rules="phoneRules"
                              style="direction: ltr"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-autocomplete
                              v-model="profileInfo.country"
                              :items="countries"
                              item-text="name"
                              item-value="id"
                              :label="$t('$vuetify.clientStepper.basicInformation.country')"
                              hide-details
                              append-icon="fas fa-flag"
                              single-line
                              outlined
                              :rules="requiredRules"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-autocomplete
                              menu-props="auto"
                              :label="$vuetify.lang.t('$vuetify.profileTZ')"
                              v-model="profileInfo.timezone"
                              :items="timezones"
                              item-value="value"
                              hide-details
                              append-icon="fas fa-clock"
                              single-line
                              outlined
                              :rules="requiredRules"
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                              outlined
                              :value="profileInfo.dateOfBirth | toJalali(isJalali)"
                              :label="$t('$vuetify.profileBirthDate')"
                              prepend-icon="event"
                              readonly
                              :rules="requiredRules"
                              @click="showDialog=true"
                          ></v-text-field>
                          <AdminDatePicker @close="showDialog=false"
                                           :dialog="showDialog"
                                           @jalaliChange="changeIsJalali"
                                           :defaultDate="profileInfo.dateOfBirth"
                                           @input="showDialog = false"
                                           v-model="profileInfo.dateOfBirth"
                          ></AdminDatePicker>
                          <!--                          @jalaliChange="alert('x')"-->


                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-select
                              v-model="profileInfo.married"
                              :items="maritalStatus"
                              item-text="title"
                              item-value="value"
                              :label="$vuetify.lang.t('$vuetify.profileCivilStatus')"
                              outlined
                              :rules="requiredRules"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                              append-icon="fas fa-building"
                              v-model="profileInfo.city"
                              outlined
                              :rules="requiredRules"
                              :label="$vuetify.lang.t('$vuetify.profileCity')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-select
                              :items="knway"
                              item-text="title"
                              item-value="value"
                              v-model="knway_selected"
                              outlined
                              :rules="requiredRules"
                              :label="$vuetify.lang.t('$vuetify.profileKnway')"
                          >
                          </v-select>

                          <transition name="fade">
                            <div v-if="knway_selected == 'other'">
                              <v-text-field
                                  v-model="knway_selected_mata"
                                  outlined
                                  :label="$vuetify.lang.t('$vuetify.profileKnwayOther')"
                                  :rules="requiredRules"
                              ></v-text-field>
                            </div>
                          </transition>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <div data-v-step="1">
                      <v-btn
                          color="primary"
                          rounded
                          class="px-10"
                          @click="saveProfile"
                      >
                        {{ $t('$vuetify.clientStepper.continue') }}
                      </v-btn>
                    </div>
                  </v-card-actions>
                </v-card>


              </v-stepper-content>
              <v-stepper-content step="2">
                <v-card
                    data-v-step="2"
                    class="elevation-0 pa-0 ma-0"
                >
                  <v-card-text class="pa-0 ma-0">
                    <ClientPricingComponent
                        showBreadCrumb="false"
                        @closeWizard="closeWizard(false)"></ClientPricingComponent>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn class="elevation-0" @click="step=1" rounded>
                      <v-icon class="mx-1 grey--text text--darken-2" small>
                        {{ checkLang == "fa" ? 'fas fa-long-arrow-alt-right' : 'fas fa-long-arrow-alt-left' }}
                      </v-icon>

                      {{ $t('$vuetify.clientStepper.return') }}

                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        @click="setStep"
                        rounded
                        class="px-10"
                        :disabled="profile.state.requireBuy"
                    >
                      {{ $t('$vuetify.clientStepper.continue') }}

                    </v-btn>
                  </v-card-actions>
                </v-card>

              </v-stepper-content>
              <v-stepper-content step="3">
                <v-card
                    class="elevation-0"
                >
                  <v-card-text v-if="!hideCycles" class="pa-0 ma-0">
                    <AddNewCycleDashboard @cycleAdded="postCycleAdd"
                                          data-v-step="3"
                                          showTitle="false"
                                          :dialog="false"></AddNewCycleDashboard>
                  </v-card-text>
                  <v-card-text v-else>
                    <v-progress-linear
                        active
                        indeterminate
                        query
                        rounded
                        stream
                    ></v-progress-linear>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn class="elevation-0" @click="step=2" rounded>
                      <v-icon class="mx-1 grey--text text--darken-2" small>
                        {{ checkLang == "fa" ? 'fas fa-long-arrow-alt-right' : 'fas fa-long-arrow-alt-left' }}
                      </v-icon>
                      {{ $t('$vuetify.clientStepper.return') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <!--<v-btn-->
                    <!--v-if="profile.cycles.length==0"-->
                    <!--color="primary"-->
                    <!--rounded-->
                    <!--class="px-10"-->
                    <!--@click="addCycle"-->
                    <!--&gt;-->
                    <!--ادامه-->
                    <!--</v-btn>-->
                  </v-card-actions>
                </v-card>

              </v-stepper-content>

              <!--<v-stepper-content step="4">-->
              <!--<v-card>-->
              <!--&lt;!&ndash;<v-card-title>انتخاب مشاور</v-card-title>&ndash;&gt;-->
              <!--&lt;!&ndash;<v-divider></v-divider>&ndash;&gt;-->
              <!--<v-card-text>-->
              <!--<v-alert type="info">-->
              <!--لطفا مشاور خود را انتخاب نمایید-->
              <!--</v-alert>-->
              <!--<v-row class="text-center" justify="center">-->


              <!--<v-col v-for="doctor in doctors" :key="doctor.id" cols="12" sm="6" md="3"-->
              <!--class="text-center">-->
              <!--<v-card-->
              <!--class="text-center "-->
              <!--@click="selectDoctor(doctor)"-->
              <!--&gt;-->
              <!--<v-card-text>-->
              <!--<v-overlay-->
              <!--v-if="selectedDoctor != null"-->
              <!--absolute-->
              <!--z-index="0"-->
              <!--opacity=".6"-->
              <!--:value="selectedDoctor.id == doctor.id"-->
              <!--color="rgba(0,250,150,.4)"-->
              <!--&gt;-->

              <!--</v-overlay>-->

              <!--<template-->
              <!--v-if="selectedDoctor != null"-->
              <!--&gt;-->
              <!--<v-btn-->
              <!--fab-->
              <!--small-->
              <!--v-if="selectedDoctor.id == doctor.id"-->
              <!--class="green cursor-pointer green-check elevation-0"-->
              <!--&gt;-->
              <!--<v-icon class="white&#45;&#45;text" small>-->
              <!--fas fa-check-->
              <!--</v-icon>-->
              <!--</v-btn>-->
              <!--</template>-->


              <!--<v-avatar-->
              <!--:size="100"-->
              <!--class="elevation-2"-->
              <!--&gt;-->

              <!--<v-img-->
              <!--v-if="doctor.avatar != null"-->
              <!--:src="doctor.avatar"-->
              <!--&gt;</v-img>-->
              <!--</v-avatar>-->
              <!--</v-card-text>-->

              <!--<v-card-text class="text-center title pa-0 ma-0">-->
              <!--<span>{{doctor.full_name}}</span>-->
              <!--</v-card-text>-->
              <!--<v-card-text>-->

              <!--{{doctor.profession}}-->

              <!--</v-card-text>-->
              <!--<v-card-subtitle v-if="doctor.hasOwnProperty('total')">-->
              <!--تعداد بسته های شما با مشاور: {{doctor.total}}-->
              <!--</v-card-subtitle>-->
              <!--</v-card>-->

              <!--</v-col>-->


              <!--</v-row>-->
              <!--<v-row justify="center" align="center">-->
              <!--<v-col class="text-center">-->
              <!--<v-btn v-if="canRequestNewDoctors" class="elevation-1"-->
              <!--@click="selectNewDoctorPass">انتخاب-->
              <!--مشاور جدید-->
              <!--</v-btn>-->
              <!--</v-col>-->
              <!--</v-row>-->
              <!--</v-card-text>-->
              <!--<v-card-actions>-->
              <!--<v-btn @click="step&#45;&#45;" text>قبلی</v-btn>-->

              <!--<v-spacer></v-spacer>-->

              <!--<v-btn-->
              <!--color="primary"-->
              <!--@click="sendSessionRequest"-->
              <!--&gt;-->
              <!--ثبت-->
              <!--</v-btn>-->

              <!--</v-card-actions>-->
              <!--</v-card>-->
              <!--</v-stepper-content>-->

              <v-stepper-content step="4">
                <DateTimeComponent
                    @done="redirect"
                    :profile="profile"
                    @getProfile="updateProfile"
                    :message="message"
                ></DateTimeComponent>

              </v-stepper-content>

            </v-stepper-items>
          </v-stepper>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
/*eslint-disable*/


import AddNewCycleDashboard from '@/components/clients/AddNewCycleDashboard';
import ClientPricingComponent from "../products/ClientPricingComponent";
import {mapGetters} from 'vuex';
import timezones from "../../data/timezones";
import moment from 'moment-timezone';
import momentJalali from 'moment-jalaali';
import {EventBus} from "@/event-bus";
import DoctorTimes from '@/components/clients/DoctorTimes'
import DateTimeComponent from '@/components/DateTimeComponent';
import ChangeTzForm from '@/components/ChangeTzFormComponent'
import AdminDatePicker from '@/components/AdminDatePicker';
import LS from "../../LocalStorateAPI";
import {parsePhoneNumberFromString, AsYouType} from "libphonenumber-js";


export default {
  props: {
    sessionRequest: {
      default: false
    }
  },
  created() {

    this.sortAndGenerateTimezoneOffset();
    // EventBus.$emit('setLoading', true);

    this.$store.dispatch('getCountries').then((resp) => {
      this.countries = resp.data.countries;
    });

  },
  mounted() {
    this.$store.dispatch('getUserInfo').then(() => {

      // let localVal = LS.get('show-tour');

      // if (this.profile.state.requireSign || localVal === 'true') {
      //     // LS.set('show-tour', true);
      //     // this.showTour = true;
      // }


      this.setStep();
      this.fillFirstStep();
      this.fillThirdStep();
      this.loaded = true;
    }).finally(() => {
      // EventBus.$emit('setLoading', false);
    });

  },
  filters: {
    toJalali(date, isJalali) {
      if (date) {
        if (isJalali) {
          return momentJalali(date, "YYYY-M-DD").format('jYYYY/jMM/jDD');
        } else {
          return date;
        }
      }
      return date;
    },
  },
  methods: {
    changeIsJalali(val) {
      this.isJalali = val;
    },
    sendWebEngageData(data) {
      Object.keys(data).forEach((item) => {
        window.webengage.user.setAttribute(item, data[item]);
      });
    },
    closeWizard(clearStorage = true) {
      this.wizard = false;
      this.$emit('closed');
      // if (clearStorage) {
      //     if (this.showTour) {
      //         LS.remove('show-tour');
      //         this.showTour = false;
      //     }
      // }

    },
    // checkSecondStepTour() {
    //     setTimeout(() => {
    //         if (this.step == 2) {
    //             this.checkTour('secondStep');
    //         }
    //     }, 100)
    //
    // },
    // checkTour(step) {
    //     if (this.showTour) {
    //         setTimeout(() => {
    //             this.$nextTick().then(() => {
    //                 // this.$tours[step].start()
    //             })
    //         }, 300)
    //
    //     }
    // },
    myCustomPreviousStepCallback(currentStep) {
      console.log(currentStep);
    },
    myCustomNextStepCallback(currentStep) {
      console.log(currentStep);
    },
    updateProfile() {
      // this.$store.dispatch('getUserInfo').then(() => {
      //     this.profile = this.$store.getters.profile;
      // })
    },
    redirect() {
      // if (!this.sessionRequest) {
      //     // this.$router.push({name: 'sessions'});
      // } else {
      //     this.$emit('closed');
      //
      // }
      this.closeWizard();
      // this.$router.push({name: 'sessions', params: {lang: 'fa'}});
    },
    selectNewDoctorPass() {
      this.selectedDoctor = null;
      this.newDoctor = true;
      this.sendSessionRequest();
    },
    selectTime(hours) {
      this.selectedSessionTimes = hours;
    },
    saveTimeForRequest() {

      if (this.selectedDoctor != null) {

        if (!this.selectedSessionTimes.length && Object.keys(this.doctorTimes).length) {

          EventBus.$emit('notify', 'red', undefined, this.$t('$vuetify.Notify.clientDashboard.selectTime'));

        } else if (!this.selectedSessionTimes.length && Object.keys(this.doctorTimes).length == 0) {

          this.step = 6;

        } else {

          let payload = {
            times: this.selectedSessionTimes,
            sessions: this.sessions
          };

          this.$store.dispatch('selectMultipleSessionRequestTime', payload).then((resp) => {
            EventBus.$emit('notify', 'green', undefined, this.$t('$vuetify.Notify.successfully'));
            this.step = 6;
          });
        }

      } else {
        this.$router.push('sessions');
      }
    },
    fillThirdStep() {
      this.cycles = this.profile.cycles;

      //SESSION COUNT
      // if (this.profile.wallet.global_packages > this.profile.wallet.dr_packages) {
      //     this.sessionCount = this.profile.wallet.global_packages;
      // } else {
      //     this.sessionCount = this.profile.wallet.dr_packages;
      // }

      if (this.cycles.length >= 1) {
        this.selectedCycle = this.cycles[0];
      }
    },
    fillFirstStep() {
      this.profileInfo.name = this.profile.firstname;
      this.profileInfo.family = this.profile.lastname;
      this.profileInfo.dateOfBirth = this.profile.birthDate;
      this.profileInfo.mobile = this.profile.cel;
      this.profileInfo.married = this.profile.married;
      this.profileInfo.city = this.profile.city;
      this.knway_selected = this.profile.knway
      this.knway_selected_mata = this.profile.knway_meta

      if (!this.profile.requiredFields.includes('timezone')) {
        this.profileInfo.timezone = this.profile.tz;
      }
      this.profileInfo.country = this.profile.country_id;
    },
    sortAndGenerateTimezoneOffset() {
      let x = JSON.parse(JSON.stringify(timezones));
      let tempTime = x.map((item) => {
        let tznum = moment.tz(item.text).format('Z');

        item.offset = moment.tz(item.text).utcOffset();
        item.text = item.text + ' ' + tznum;
        return item;
      });
      tempTime = tempTime.sort((a, b) => {
        return a.offset - b.offset;
      });
      this.timezones = tempTime;
    },
    postCycleAdd(data) {

      this.sessionCount = data.sessionCount;
      if (data.cycle == null || data.cycle == undefined) {
        this.cycles = this.profile.cycles;
        this.selectedCycle = this.profile.cycles[0];
      } else {
        this.selectedCycle = data.cycle;
      }

      EventBus.$emit('setLoading', true);
      this.getDoctors(this.selectedCycle).then(() => {

        if (this.canRequestNewDoctors) {

          this.selectedDoctor = null;
          this.newDoctor = true;

          let options = {
            "cycle_id": this.selectedCycle.id,
            "session_count": this.sessionCount
          };

          this.$store.dispatch('sendSessionRequest', options).then((resp) => {
            // EventBus.$emit('notify', 'green', undefined, 'درخواست شما با موفقیت به ثبت رسید');
            // if (this.showTour) {
            //     this.showCalendarTour = true;
            // }
            if (this.hideCycles) {
              this.hideCycles = false;
            }
            this.sessionRequested = true;
            if (!this.profile.state.hadSessions) {
              this.message = this.$t('$vuetify.Notify.successDateTime');
            }
            this.step = 4;
          });

        }
      }).finally(() => {
        EventBus.$emit('setLoading', false);
      });


      // this.getDoctors(this.selectedCycle).then(() => {
      //     if (this.doctors.length == 0 && this.canRequestNewDoctors) {
      //
      //         this.selectedDoctor = null;
      //         this.newDoctor = true;
      //
      //         let options = {
      //             "cycle_id": this.selectedCycle.id,
      //             "session_count": this.sessionCount
      //         };
      //
      //         if (this.selectedDoctor != null) {
      //             options.doctor_id = this.selectedDoctor.id;
      //
      //         }
      //
      //         this.$store.dispatch('sendSessionRequest', options).then((resp) => {
      //             EventBus.$emit('notify', 'green', undefined, 'درخواست شما با موفقیت به ثبت رسید');
      //             if (this.showTour) {
      //                 this.showCalendarTour = true;
      //             }
      //             this.step = 5;
      //         });
      //
      //     } else {
      //         this.step++;
      //     }
      // }).finally(() => {
      //     EventBus.$emit('setLoading', false);
      // });

    },
    addCycle() {
      EventBus.$emit('postConsultationInfoEvent');
      // this.setStep();
    },
    sendSessionRequest() {
      if (!this.newDoctor && this.selectedDoctor == null) {
        EventBus.$emit('notify', 'red', undefined, this.$t('$vuetify.Notify.clientDashboard.selectOne'));
        return;
      }

      let options = {
        "cycle_id": this.selectedCycle.id,
        "session_count": this.sessionCount
      };
      if (this.selectedDoctor != null) {
        options.doctor_id = this.selectedDoctor.id;

      } else {
        this.completed = true;
      }
      EventBus.$emit('setLoading', true);
      this.$store.dispatch('sendSessionRequest', options).then((resp) => {
        if (resp.data.hasOwnProperty('doctorTimes')) {
          this.doctorTimes = resp.data.doctorTimes;
        }

        if (resp.data.hasOwnProperty('sessions')) {
          this.maxSelectSessionTime = resp.data.sessions.length;
          this.sessions = resp.data.sessions;
        }
        this.sessionRequested = true;
        EventBus.$emit('notify', 'green', undefined, this.$t('$vuetify.Notify.successfullyRequest'));
        this.step = 4;
        //
        // if (Object.keys(this.doctorTimes).length) {
        //     this.step = 5;
        // } else {
        //     this.step = 6;
        // }


        // this.step++;
        // this.$store.dispatch('getUserInfo');
        // if (this.selectedDoctor == null) {
        //     this.completed = true;
        // }
        //
        // if (resp.data.hasOwnProperty('doctorTimes')) {
        //     this.doctorTimes = resp.data.doctorTimes;
        // }
        //
        // if (resp.data.hasOwnProperty('sessions')) {
        //     this.maxSelectSessionTime = resp.data.sessions.length;
        //     this.sessions = resp.data.sessions;
        // }
        //
        // this.$nextTick().then(() => {
        //     this.e1 = 3;
        // });
      }).finally(() => {
        EventBus.$emit('setLoading', false);
      });

    },
    selectDoctor(doctor) {
      this.newDoctor = false;
      if (this.selectedDoctor == doctor) {
        this.selectedDoctor = null;
      } else {
        this.selectedDoctor = doctor;
      }
    },
    selectNewDoctor() {
      this.selectedDoctor = null;

      this.newDoctor = !this.newDoctor;
    },
    getDoctors(cycle) {
      return new Promise((resolve, reject) => {
          this.$store.dispatch('getSessionRequestDoctors', {cycleId: cycle.id}).then((resp) => {
          this.doctors = resp.data.results.doctors;

          this.selectedDoctor = null;
          this.newDoctor = false;

          if (this.doctors.length == 1) {
            this.selectedDoctor = this.doctors[0];
          }

          if (resp.data.selected_doctor != null) {
            this.selectedDoctor = this.doctors.find(doctor => doctor.id == resp.data.selected_doctor.id);
          }


          this.canRequestNewDoctors = resp.data.can_request_new_doctor;
          resolve();
        }).catch((resp) => {
          reject();
        })
      })

    },


    sendTimes() {

      let data = {timebooks: []};

      this.selectedTimes.forEach(day => {
        let start = "";
        let end = "";

        data.timebooks.push({'date': day.date.replace(/-/g, '/'), 'periods': []});

        day.periods.forEach(time => {
          start = time.from;
          end = time.to;
          data.timebooks.forEach(item => {
            item.periods.push({'start': start + ':00', 'end': end + ':00'})
          })
        })
      });

      EventBus.$emit('setLoading', true);
      this.$store.dispatch('clientPostConsultInfo', data).then(() => {

        EventBus.$emit('notify', 'green', undefined, this.$t('$vuetify.Notify.clientDashboard.success'));
        if (!this.sessionRequest) {
          this.$router.push({name: 'sessions'});
        } else {
          this.$emit('closed');
        }

      }).finally(() => {
        EventBus.$emit('setLoading', false);
      })
    },

    unique(arr) {
      let a = arr.concat();
      for (let i = 0; i < a.length; ++i) {
        for (let j = i + 1; j < a.length; ++j) {
          if (a[i] === a[j])
            a.splice(j--, 1);
        }
      }

      return a;
    },
    removeTime(time, timePeriod) {
      time.periods.splice(time.periods.indexOf(timePeriod), 1);
      if (!time.periods.length) {
        this.removeTimeRecord(time);
      }
    },
    removeTimeRecord(time) {
      this.selectedTimes.splice(this.selectedTimes.indexOf(time), 1);
    },

    addToTimes() {
      if (this.$refs.addToTimesForm.validate()) {

        if (!this.currentTime.periods.length) {
          EventBus.$emit('notify', 'red', undefined, this.$t('$vuetify.Notify.clientDashboard.selectTimespan'));
        } else {
          let x = {
            date: this.currentTime.date,
            periods: this.currentTime.periods
          };
          let exists = this.selectedTimes.find((time) => {
            return time.date == x.date;
          });

          if (exists != undefined) {
            // this.removeTime(exists);
            // this.exists.periods

            exists.periods = this.unique(exists.periods.concat(x.periods));
            // console.log(exists.periods.filter(value => x.periods.includes(value)));
            // this.removeTimeRecord(exists);
            // this.selectedTimes.push(toAdd);
          } else {

            this.selectedTimes.push(x);
          }
          this.currentTime.date = '';
          this.currentTime.periods = [];
          this.showAddDialog = false;

        }
      }
    },
    selectPeriod(period) {

      if (!this.currentTime.periods.includes(period)) {
        this.currentTime.periods.push(period);
      } else {
        this.currentTime.periods.splice(this.currentTime.periods.indexOf(period), 1);
      }
    },
    saveProfile() {
      let data = {
        name: this.profileInfo.name,
        family: this.profileInfo.family,
        timezone: this.profileInfo.timezone,
        country_id: this.profileInfo.country,
        birth_date: this.profileInfo.dateOfBirth,
        marital_state: this.profileInfo.married,
        city: this.profileInfo.city,
        mobile: this.profileInfo.mobile.replaceAll(' ', ''),
        familiar_by: this.knway_selected,
        familiar_by_meta: this.knway_selected_mata,
      };
      // let country = ;
      // console.log(data.country_id);
      // console.log(this.countries.find(item => item.id == data.country_id).name);
      // return;
      let webengageData = {
        // we_country: this.countries.find(item => item.id == data.country_id).name,
        we_email: this.profile.email,
        we_first_name: data.name,
        we_last_name: data.family,
        timezone: data.timezone,
      };
      if (this.$refs.profileForm.validate()) {
        EventBus.$emit('setLoading', true);
        this.$store.dispatch('saveUserInfo', data).then(() => {
          this.sendWebEngageData(webengageData);
          this.$store.dispatch('getUserInfo').then(() => {
            // EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');
            EventBus.$emit('clientProfileSaved');
            this.setStep();
          });
        }).catch(() => {
          EventBus.$emit('clientProfileSaveError');
        }).finally(() => {
          EventBus.$emit('clientProfileSaveError');
          EventBus.$emit('setLoading', false);
        });
      }
      // this.setStep();
    },
    setStep() {
      if (this.profile.state.requireSign) {
        this.step = 1;
        // this.checkTour('firstStep');
      } else if (this.profile.state.requireBuy && (!this.profile.state.hasPendingSessions || this.sessionRequest)) {
        // } else if (this.profile.state.requireBuy && !this.profile.state.hasPendingSessions) {

        this.step = 2;

      } else if (this.profile.state.requireCycle || !this.profile.state.hasPendingSessions || this.sessionRequest) {
        // this.$store.commit('setCycleById', this.profile.state.requireCycleId);
        this.step = 3;

        if (this.sessionRequest && this.profile.cycles.length == 1) {
          this.hideCycles = true;
          this.postCycleAdd({
            cycle: this.profile.cycles[0],
            sessionCount: 1
          });
          return;
        }


        // if (this.profile.cycles.length == 0) {
        //     this.checkTour('thirdStep');
        // }

        // }
        // else if (this.profile.state.requireFillTimebooks) {
        //     this.step = 4;
      } else if (this.profile.state.requireFillTimebooks) {
        // if (this.showTour) {
        //     this.showCalendarTour = true;
        // }

        this.step = 4;
        if (!this.profile.state.hadSessions) {
          this.message = this.$t('$vuetify.Notify.successDateTime');
        }
      } else {
        this.closeWizard();
      }
    }
  },
  components: {
    // AddNewCycle,
    AdminDatePicker,
    ChangeTzForm,
    DateTimeComponent,
    DoctorTimes,
    AddNewCycleDashboard,
    ClientPricingComponent
  },
  data() {
    return {
      knway_selected:null,
      knway_selected_mata:null,
      knway: [
        {value: "google", title: this.$t('$vuetify.knway.google')},
        {value: "twitter", title: this.$t('$vuetify.knway.twitter')},
        {value: "instagram", title: this.$t('$vuetify.knway.instagram')},
        {value: "podcast", title: this.$t('$vuetify.knway.podcast')},
        {value: "bannerads", title: this.$t('$vuetify.knway.bannerads')},
        {value: "friends", title: this.$t('$vuetify.knway.friends')},
        {value: "site", title: this.$t('$vuetify.knway.site')},
        {value: "other", title: this.$t('$vuetify.knway.other')},
      ],
      maritalStatus: [
        {value: "single", title: this.$t('$vuetify.relationships.single')},
        {value: "married", title: this.$t('$vuetify.relationships.married')},
      ],
      isJalali: true,
      birthdate: null,
      showDialog: false,
      zone: 'UTC',
      phoneCountry: null,
      hideCycles: false,
      sessionRequested: false,
      message: this.$t('$vuetify.Notify.successfully'),
      showTour: false,
      showCalendarTour: false,
      myCallbacks: {
        onPreviousStep: this.myCustomPreviousStepCallback,
        onNextStep: this.myCustomNextStepCallback
      },
      currentStep: 0,
      myOptions: {
        labels: {
          buttonSkip: 'بستن',
          buttonPrevious: 'قبلی',
          buttonNext: 'بعدی',
          buttonStop: 'پایان'
        }
      },
      // tourSteps: {
      //     first: [
      //         {
      //             target: '[data-v-step="0"]',
      //             params: {
      //                 enableScrolling: false,
      //                 placement: 'top'
      //             },
      //             header: {
      //                 title: 'خوش آمدید',
      //             },
      //             content: `ابتدا اطلاعات خود را کامل کنید`
      //         },
      //         {
      //             target: '[data-v-step="1"]',
      //             params: {
      //                 enableScrolling: true,
      //                 placement: 'top'
      //             },
      //             header: {
      //                 title: 'تکمیل اطلاعات',
      //             },
      //             content: `پس از تکمیل اطلاعات بر روی دکمه ادامه بزنید.`
      //         }
      //     ],
      //     second: [
      //         {
      //             target: '[data-v-step="2"]',
      //             params: {
      //                 enableScrolling: false,
      //                 placement: 'top'
      //             },
      //             header: {
      //                 title: 'خرید بسته',
      //             },
      //             content: `بسته مورد نظر خود را انتخاب نمایید`
      //         }
      //     ],
      //
      //     third: [
      //         {
      //             target: '[data-v-step="3"]',
      //             params: {
      //                 enableScrolling: false,
      //                 placement: 'top'
      //             },
      //             header: {
      //                 title: 'موضوع مشاوره',
      //             },
      //             content: `موضوع مشاوره و تعداد جلسات خود را وارد نمایید`
      //         }
      //     ]
      // },
      // profile: this.$store.getters.getUserInfo,
      maxSelectSessionTime: 0,
      selectedSessionTimes: [],
      doctorTimes: {},
      canRequestNewDoctors: false,
      doctors: [],
      selectedDoctor: null,
      newDoctor: false,

      sessionCount: 1,
      cycles: [],
      selectedCycle: null,
      currentTime: {
        date: '',
        periods: []
      },
      selectedTimes: [],

      periods: [
        {title: 'صبح', from: '8', to: '12'},
        {title: 'ظهر', from: '12', to: '16'},
        {title: 'عصر', from: '16', to: '20'},
        {title: 'شب', from: '20', to: '24'},
      ],
      menu: false,
      showAddDialog: false,
      loaded: false,
      timezones: timezones,
      profileInfo: {
        name: '',
        family: '',
        country: '',
        timezone: '',
        mobile: '',
        dateOfBirth: null,
      },
      countries: [],
      country: null,
      step: 0,
      wizard: true
    }
  },
  computed: {
    checkLang() {
      return this.$route.params.lang == "fa" || this.$route.params.lang == undefined ? 'fa' : 'en';
    },
    offsetAppliedTimezones() {

      return this.timezones;
    },
    evaluatedCountry() {
      return this.countries.find(item => item.code == this.phoneCountry)
    },
    phoneRules() {
      return [
        input => {
          let phone = input.replaceAll(' ', '');
          const phoneNumber = parsePhoneNumberFromString(phone);
          if (phoneNumber) {
            if (!phoneNumber.isValid()) {
              return this.$t('$vuetify.Notify.signupComponent.phoneNumberError');
            } else {
              return true;
            }
          } else {
            return this.$t('$vuetify.zeroPayment.phoneRequired');
          }
        }
      ]
    },
    sessionCountRules() {
      return [
        value => !!value || this.$t('$vuetify.Notify.sessionCountRules.countRequired'),
        value => (value > 0) || this.$t('$vuetify.Notify.sessionCountRules.minCount'),
        value => (value <= this.profile.wallet.total) || this.$t('$vuetify.Notify.sessionCountRules.buyError'),
      ];
    },
    needsCycle() {
      return this.profile.state.requireCycle
    },
    requiredRules() {
      return [v => !!v || this.$vuetify.lang.t('$vuetify.fillIsRequired')];
    },
    ...mapGetters({
      profile: 'getUserInfo',
      platform: 'getPlatform',
      cart: 'getCart'
    }),
  },
  watch: {
    evaluatedCountry(country) {
      if (country != undefined && country != null) {
        // if(this.profileInfo.country == null || this.profileInfo.country==undefined)
        if (this.profile.cel == null || this.profile.cel == undefined)
          this.profileInfo.country = country.id;
      }
    },
    phoneCountry(val) {
      if (val != undefined && val != null) {
        console.log(val)
      }
    },
    'profileInfo.mobile'() {
      if (this.profileInfo.mobile.startsWith('00')) {
        this.profileInfo.mobile = this.profileInfo.mobile.substr(2);
        this.profileInfo.mobile = '+' + this.profileInfo.mobile;
      }

      if (this.profileInfo.mobile.startsWith('098')) {
        this.profileInfo.mobile = this.profileInfo.mobile.substr(1);
        this.profileInfo.mobile = '+' + this.profileInfo.mobile;
      }

      if (this.profileInfo.mobile.startsWith('09')) {
        this.profileInfo.mobile = this.profileInfo.mobile.substr(1);
        this.profileInfo.mobile = '+98' + this.profileInfo.mobile;
      }

      this.profileInfo.mobile = new AsYouType().input(this.profileInfo.mobile);

    },
    selectedCycle() {

      if (!this.profile.state.requireSign && !this.profile.state.requireBuy && this.profile.wallet.total) {
        // this.getDoctors(this.selectedCycle);
      }
    },
    wizard() {
      // if (!this.sessionRequest) {
      //     this.$router.push({name: 'sessions'});
      // }
      this.closeWizard();

    }

  }

}
</script>

<style scoped>
.green-check {
  position: absolute !important;
  top: -10px !important;
  right: -10px !important;
}

.fade-enter-active, .fade-leave-active {
  transform: translateX(0px);
  opacity: 1;
  transition: all .9s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  transform: translateX(-100%);
  opacity: 0;
}

</style>