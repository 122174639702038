<template>
  <v-card class="elevation-0">
    <v-dialog v-model="requestConfirmDialog" max-width="400">
      <v-card>
        <v-card-title>
          {{$t('$vuetify.clientStepper.addCycleModal.selectCycle.confirm')}}
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text class="pa-5">
          آیا نسبت به درخواست {{ sessionCount }} جلسه اطمینان دارید؟
        </v-card-text>

        <v-card-actions>
          <v-btn class="red white--text" text @click="requestConfirmDialog = false">
            {{$t('$vuetify.clientStepper.close')}}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn class="primary" text @click="sessionRequestConsent">
            {{$t('$vuetify.clientStepper.confirm')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-title v-if="show_title">
      {{ pageTitle }}
    </v-card-title>

    <v-card-text :class="{'':!show_title}">
      <v-window v-model="window">
        <v-window-item :value="1">
          <v-card class="mb-3">
            <v-card-title class="purple-light white--text font-weight-light subtitle-1 pa-3">
              {{$t('$vuetify.clientStepper.addCycleModal.selectCycle.selecting')}}
              <v-spacer></v-spacer>
              <v-btn small @click="window=2">
                {{$t('$vuetify.clientStepper.addCycleModal.selectCycle.action')}}
              </v-btn>
            </v-card-title>
          </v-card>
          <v-row justify="center" align="center">
            <v-col v-for="cycle in profile.cycles" :key="cycle.id" cols="12" sm="6" md="4">
              <v-card>
                <v-card-title class="pb-2">
                  {{$t('$vuetify.clientStepper.addCycleModal.selectCycle.issueSession')}}
                  {{ cycle.heading }}
                </v-card-title>
                <v-divider></v-divider>
                <v-card-subtitle>
                  {{$t('$vuetify.clientStepper.addCycleModal.selectCycle.totalSession')}}
                   {{ cycle.sessionCount }}
                </v-card-subtitle>

                <v-card-actions>
                  <v-btn
                      color="primary"
                      rounded
                      block
                      @click="requestSession(cycle)"
                  >
                    {{$t('$vuetify.clientStepper.addCycleModal.selectCycle.request')}}
                  </v-btn>

                  <v-spacer></v-spacer>

                </v-card-actions>


              </v-card>
            </v-col>
          </v-row>
        </v-window-item>
        <v-window-item :value="2">

          <v-card class="mb-3">
            <v-card-title class="purple-light white--text font-weight-light subtitle-1 pa-3">
              {{$t('$vuetify.clientStepper.addCycleModal.title')}}
              <v-spacer></v-spacer>
              <v-btn small @click="window=1" v-if="profile.cycles.length>0">
                {{$t('$vuetify.clientStepper.addCycleModal.selectCycle.title')}}
              </v-btn>
            </v-card-title>

          </v-card>
          <v-form
              ref="addCycleForm"
          >
            <template>
              <v-row dense>
                <v-col cols="12" sm="8" md="4"
                >
                  <v-select
                      :items="categories"
                      v-model="title"
                      :label="$t('$vuetify.clientStepper.addCycleModal.categorySession')"
                      outlined
                      :rules="[rules.requiredProblemCategory]"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" md="8" sm="8" class="text-center"
                >
                  <v-textarea
                      v-model="description"
                      outlined
                      :placeholder="$t('$vuetify.clientStepper.addCycleModal.problemDescriptionPlaceHolder')"
                      :label="$t('$vuetify.clientStepper.addCycleModal.problemDescription')"
                      :rules="[rules.requiredRule]"
                  ></v-textarea>
                </v-col>
              </v-row>
            </template>

            <!--<v-col cols="12" md="4" sm="8" class="text-center">-->


            <!--&lt;!&ndash;<v-text-field&ndash;&gt;-->
            <!--&lt;!&ndash;outlined&ndash;&gt;-->
            <!--&lt;!&ndash;label="تعداد جلسات"&ndash;&gt;-->
            <!--&lt;!&ndash;placeholder="تعداد جلسات را وارد کنید"&ndash;&gt;-->
            <!--&lt;!&ndash;v-model="sessionCount"&ndash;&gt;-->
            <!--&lt;!&ndash;:rules="sessionCountRules"&ndash;&gt;-->
            <!--&lt;!&ndash;&gt;</v-text-field>&ndash;&gt;-->
            <!--</v-col>-->
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                rounded
                class="px-10"
                @click="addNewCycle"
            >
              {{ $t('$vuetify.clientStepper.addCycleModal.add') }}

            </v-btn>
          </v-card-actions>


        </v-window-item>
      </v-window>
    </v-card-text>
    <v-card-actions v-if="dialog">
      <v-btn class="primary" @click="addNewCycle">
        {{$t('$vuetify.clientStepper.continue')}}
      </v-btn>
      <v-btn class="red white--text" @click="close">
        {{$t('$vuetify.clientStepper.close')}}
      </v-btn>
    </v-card-actions>

  </v-card>
</template>

<script>
/*eslint-disable*/
import {EventBus} from "@/event-bus";
import {mapGetters} from 'vuex';


export default {
  props: {
    dialog: {
      default: false
    },
    getProfile: {
      default: true
    },
    showTitle: {
      default: true
    }
  },
  data() {
    return {
      window: 1,
      requestConfirmDialog: false,
      sessionCount: 1,
      title: null,
      show_title: true,
      description: null,
      categories: [
        this.$t('$vuetify.clientStepper.addCycleModal.categories[0]'),
        this.$t('$vuetify.clientStepper.addCycleModal.categories[1]'),
        this.$t('$vuetify.clientStepper.addCycleModal.categories[2]'),
        this.$t('$vuetify.clientStepper.addCycleModal.categories[3]'),
        this.$t('$vuetify.clientStepper.addCycleModal.categories[4]'),
        this.$t('$vuetify.clientStepper.addCycleModal.categories[5]'),
      ],
      categoryMap: [
        {title: 'فردی', cat: 'cat_1'},
        {title: 'زوج', cat: 'cat_2'},
        {title: 'خانواده', cat: 'cat_3'},
        {title: 'کودک و نوجوان', cat: 'cat_4'},
        {title: 'سکس تراپی', cat: 'cat_5'},
        {title: 'روانپزشکی', cat: 'cat_6'},
      ],
      rules: {
        requiredRule: v => !!v || this.$vuetify.lang.t('$vuetify.fillIsRequired'),
        requiredProblemCategory: v => !!v || this.$t('$vuetify.requiredInputRule'),
        lengthRule: v => v.length >= 20 || this.$t('$vuetify.addCycleModal.countChar'),

      }
    }
  },
  computed: {
    btnWH() {
      return this.$vuetify.breakpoint.smAndDown ? 50 : 80;
    },
    pageTitle() {
      return this.profile.cycles.length ? "انتخاب دوره مشاوره" : 'افزودن دوره مشاوره جدید';
    },
    sessionCountRules() {
      return [
        value => !!value || 'تعداد جلسات را وارد نمایید.',
        value => (value > 0) || 'تعداد جلسات حداقل باید 1 باشد',
        value => (value <= this.profile.wallet.total) || 'تعداد جلسات درخواستی باید از جلسات خریداری شده شما کمتر باشد',
      ];
    },
    ...mapGetters({
      profile: 'getUserInfo'
    })
  },
  created() {
    EventBus.$on('postConsultationInfoEvent', this.addNewCycle);
    this.show_title = this.showTitle !== "false";

    if (this.profile.cycles.length == 0) {
      this.window = 2;
    }


    if (this.$route.name != 'expressSession') {

      //SESSION COUNT
      // if (this.profile.wallet.global_packages > this.profile.wallet.dr_packages) {
      //     this.sessionCount = this.profile.wallet.global_packages;
      // } else {
      //     this.sessionCount = this.profile.wallet.dr_packages;
      // }

    } else {
      this.sessionCount = 1;
    }

  },
  beforeDestroy() {
    EventBus.$off('postConsultationInfoEvent', this.addNewCycle);
  },
  methods: {
    decreaseSessionCount() {
      if (this.sessionCount > 1) {
        this.sessionCount--;
      } else {
        EventBus.$emit('notify', 'red', undefined, this.$t('$vuetify.Notify.addNewCycle.oneSession'));
      }
    },
    addSessionCount() {
      let max = 0;
      if (this.profile.wallet.global_packages > this.profile.wallet.dr_packages) {
        // this.sessionCount = this.profile.wallet.global_packages;
        max = this.profile.wallet.global_packages;
      } else {
        // this.sessionCount = this.profile.wallet.dr_packages;
        max = this.profile.wallet.dr_packages;
      }

      if (this.sessionCount < max) {
        this.sessionCount++;
      } else {
        EventBus.$emit('notify', 'red', undefined, this.$t('$vuetify.Notify.addNewCycle.notEnough'));
      }

    },
    requestSession(cycle) {
      if (this.sessionCount > 1) {
        this.requestConfirmDialog = true;
      } else if (this.sessionCount == 1) {
        this.sessionRequestConsent(cycle);
      }

    },
    sessionRequestConsent(cycle) {

      this.requestConfirmDialog = false;
      this.$emit('cycleAdded', {
        sessionCount: this.sessionCount,
        cycle: cycle
      });
      return;
    },
    addNewCycle() {

      if (this.$refs.addCycleForm.validate()) {

        let payload = {
          title: this.title,
          description: this.description,
        };
        let catMap = this.categoryMap.find(item => item.title == this.title);
        // if (this.profile.cycles.length == 0) {
        //     payload.requestFirstSession = true;
        // }
        EventBus.$emit('setLoading', true);
        this.$store.dispatch('addNewCycle', payload).then(() => {

          if (catMap != undefined) {
            let data = {
              cycle_category: catMap.cat
            };
            window.webengage.track('cycle_added', data);
          }

          if (this.getProfile) {
            this.$store.dispatch('getUserInfo').then(() => {
              EventBus.$emit('notify', 'green', undefined, this.$t('$vuetify.Notify.successfully'));
              EventBus.$emit('setLoading', false);
              this.window = 1;
              // this.$emit('cycleAdded', {
              //     sessionCount: this.sessionCount
              // });
              // this.close();
            });
          } else {
            this.window = 1;
            // this.$emit('cycleAdded', {
            //     sessionCount: this.sessionCount
            // });
            // this.close();
          }


        });
      } else {
        EventBus.$emit('postConsultationInfoError');
      }
    },
    close() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>

</style>