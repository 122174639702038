<template>
    <v-navigation-drawer
            v-model="sideChat"
            fixed
            width="350px"
            class="elevation-2"
            :right="!$vuetify.rtl ? true : false"
    >
        <v-dialog v-model="imageModal" width="800px" scrollable>
            <v-card class="pa-0 ma-0">
                <v-card-text class="pa-0 ma-0">
                    <v-img :src="modalImg" v-if="modalImg!=null"></v-img>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-card dense class="elevation-0">
            <v-card-title class="pa-1 ma-0 elevation-0">
                <v-btn small fab text @click="closeSideChat">
                    <v-icon small>fas fa-times</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <div class="ml-3">
                  <v-btn small rounded class="warning" text @click="goFullScreen">
                    {{$t('$vuetify.Messenger.show')}}
                  </v-btn>
                </div>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="px-2">
                <v-window v-model="window">
                    <v-window-item :value="1">
                        <template v-if="mode!=='admin'">
                            <MessengerConversations
                                    :sidebar="true"
                                    :conversations="contacts"
                                    @conversationSelected="setActiveChat"
                                    @conversationDeSelected="deselectActiveChat"
                            ></MessengerConversations>
                        </template>
                        <template v-else-if="mode==='admin'">
                            <AdminMessengerConversations
                                    :sidebar="true"
                                    :conversations="contacts"
                                    @conversationSelected="setActiveChat"
                                    @conversationDeSelected="deselectActiveChat"
                            ></AdminMessengerConversations>
                        </template>

                    </v-window-item>
                    <v-window-item :value="2">


                        <v-responsive
                                v-if="activeChat.id!=null"
                                class="overflow-y-hidden fill-height"
                                height="500"
                        >
                            <v-card
                                    flat
                                    class="d-flex flex-column fill-height"

                            >

                                <v-card-title class="pa-0">
                                    {{getActiveChat.title}}
                                    <v-spacer></v-spacer>
                                    <v-btn x-small text class="grey lighten-4 mx-1" @click="returnToContacts">
                                        <v-icon small>fas fa-long-arrow-alt-right</v-icon>
                                    </v-btn>
                                </v-card-title>
                                <v-divider></v-divider>
                                <v-card-text class="flex-grow-1 overflow-y-auto px-2" id="messages-window">
                                    <div id="messages-list">
                                        <div class="text-center" style="position:relative;">
                                            <div v-if="!hasNextPage">
                                                <v-chip small class="grey--text lighten-4">ابتدای پیام ها</v-chip>
                                            </div>
                                            <div v-else>
                                                <v-btn small text rounded class="primary" @click="handleScroll"
                                                       :loading="loadingPreviousMessages">پیامهای پیشین
                                                </v-btn>
                                            </div>
                                        </div>

                                        <template v-for="msg in getMessages">
                                            <template v-if="msg.type=='text'">
                                                <TextMessage :mode="mode" :msg="msg" :key="msg.id"></TextMessage>
                                            </template>

                                            <template v-else-if="msg.type=='fileupload'">
                                                <FileUpload :mode="mode" :msg="msg" :key="msg.id"></FileUpload>
                                            </template>

                                            <template v-else-if="msg.type=='file'">
                                                <FileMessage :mode="mode" @magnify="showImage" :msg="msg"
                                                             :key="msg.id"></FileMessage>
                                            </template>

                                        </template>
                                    </div>
                                </v-card-text>
                                <v-divider></v-divider>
                                <v-card-text class="flex-shrink-1">

                                    <v-textarea
                                            class="pa-0 ma-0"
                                            v-model="messageForm.contents"
                                            placeholder="نوشتن پیام..."
                                            type="text"
                                            no-details
                                            append-outer-icon="send"
                                            @keyup.enter="sendMessage"
                                            @click:append-outer="sendMessage"
                                            hide-details
                                            no-resize

                                            :rows="rowCount"
                                    >

                                        <template v-slot:prepend>
                                            <label>
                                                <v-icon large>mdi-attachment</v-icon>
                                                <input style="display: none;" type="file" id="file" ref="fileInput"
                                                       v-on:change="handleFileUpload()"/>
                                            </label>
                                            <!--<button v-on:click="submitFile()">Submit</button>-->
                                        </template>

                                    </v-textarea>
                                </v-card-text>

                            </v-card>
                        </v-responsive>

                        <v-responsive v-else
                                      class="overflow-y-hidden fill-height"
                                      height="500">
                            <v-card
                                    flat
                                    class="d-flex flex-column fill-height"

                            >
                                <v-row align="center" justify="center">
                                    <v-col>
                                        <v-card-text class="text-center fill-height">
                                            <v-chip class="grey--text text--darken-1 grey  lighten-3" label>یک گفتگو
                                                انتخاب
                                                نمایید
                                            </v-chip>
                                        </v-card-text>
                                    </v-col>
                                </v-row>

                            </v-card>
                        </v-responsive>
                    </v-window-item>
                </v-window>
            </v-card-text>
        </v-card>
    </v-navigation-drawer>
</template>

<script>
    /*eslint-disable*/
    import {mapGetters} from 'vuex';
    import {EventBus} from "@/event-bus";
    import MessengerConversations from '@/components/chat/MessengerConversations';
    import AdminMessengerConversations from '@/components/chat/AdminContacts';
    import TextMessage from '@/components/chat/TextMessage';
    import FileUpload from '@/components/chat/FileUpload';
    import FileMessage from '@/components/chat/FileMessage';
    import moment from 'moment-jalaali';

    export default {
        components: {
            MessengerConversations,
            AdminMessengerConversations,
            TextMessage,
            FileUpload,
            FileMessage
        },
        computed: {
            ...mapGetters({
                profile: 'getUserInfo',
                getMessages: 'getMessages',
                getActiveChat: 'getActiveChat',
                getConversationId: 'getConversationId',
                getScrollToBottom: 'getScrollToBottom'
            }),
            rowCount() {
                if (this.messageForm.contents.length) {
                    let ratio = Math.ceil(this.messageForm.contents.length / 150);
                    return (ratio < 5) ? ratio : 5;
                }
                return 1;
            }
        },
        created() {
            EventBus.$on('toggleSideChat', this.handleSideChat)
            this.mode = this.profile.role;
            this.initAPIs();
            this.getContacts();
        },
        beforeDestroy() {
            EventBus.$off('toggleSideChat', this.handleSideChat);
        },
        data() {
            return {
                window: 1,
                sideChat: false,
                mode: null,
                contactsApi: null,
                sendMessageAPI: null,
                getConversationMessage: null,
                contacts: [],
                activeChat: {
                    id: null,
                    title: null
                },
                modalImg: null,
                imageModal: false,
                lastHeight: 0,
                hasNextPage: false,
                loadingPreviousMessages: false,
                activeConversation: {
                    id: null
                },
                page: 1,


                messages: [],
                messageForm: {
                    contents: "",
                    is_mine: true,
                    date: "11:11am",
                    type: 'text'
                }
            }
        },
        methods: {
            closeSideChat() {
                this.window = 1;
                this.sideChat = false;
                this.deselectActiveChat();
            },
            goFullScreen() {
                this.closeSideChat();
                this.$router.push({name:'messenger'});
            },
            showImage(imgData) {
                this.imageModal = true;
                this.modalImg = imgData;
            },
            scrollToBottom() {
                let messageElem = document.getElementById('messages-list');
                document.getElementById('messages-window').scrollTop = messageElem.clientHeight;
            },
            handleFileUpload() {

                this.file = this.$refs.fileInput.files[0];


                let now = new Date();
                let message = {
                    contents: this.file.name,
                    is_mine: true,
                    created_at: new moment().format('jYYYY/jMM/jDD HH:mm'),
                    type: 'fileupload',
                    mime: this.file.type,
                    body: this.file,
                    conversation: this.activeConversation
                };
                this.messages.push(message);
            },
            returnToContacts() {
                this.window = 1;
                this.deselectActiveChat();
            },
            addListeners() {
                let messageWindow = document.getElementById('messages-window');
                messageWindow.addEventListener('scroll', this.handleScroll);

            },
            handleScroll(event) {
                if (event.target.scrollTop <= 10) {
                    if (!this.loadingPreviousMessages && this.hasNextPage) {
                        this.loadingPreviousMessages = true;

                        this.page++;
                        this.getConversationMessages(this.activeChat);

                    }
                }
            },
            sendMessage() {
                let options = {
                    id: this.activeConversation.id,
                    payload: {
                        contents: this.messageForm.contents,
                        type: 'text'
                    }
                };


                this.messageForm.date = new moment().format('jYYYY/jMM/jDD HH:mm');
                let clone = JSON.parse(JSON.stringify(this.messageForm));

                this.messages.push(clone);

                this.$nextTick().then(() => {
                    this.scrollToBottom();
                })

                this.messageForm = {
                    contents: "",
                    is_mine: true,
                    date: "",
                    type: 'text'
                };
                this.$store.dispatch(this.sendMessageAPI, options).then((resp) => {
                    console.log(resp);
                })

            },
            getConversationMessages(chat) {
                console.log(chat);
                let options = {
                    id: chat.id,
                    page: this.page
                };

                let messageElem = document.getElementById('messages-list');
                if (messageElem != null) {
                    this.$nextTick(() => {
                        this.lastHeight = messageElem.clientHeight
                    })
                }


                this.$store.dispatch(this.getConversationMessage, options).then((resp) => {
                    if (resp.data.pagination.next_page_url != null) {
                        this.hasNextPage = true;
                    } else {
                        this.hasNextPage = false;
                    }
                    this.activeConversation.id = resp.data.conversation.id;
                    this.messages.unshift(...resp.data.messages.reverse());

                    this.$store.commit('SET_MESSAGES', {
                        messages: this.messages,
                        conversationId: resp.data.conversation.id
                    });

                    this.loadingPreviousMessages = false;
                    EventBus.$emit('setLoading', false);

                    this.window = 2;
                    this.$nextTick().then(() => {
                        setTimeout(() => {
                            let messageElem = document.getElementById('messages-list');
                            let top = messageElem.clientHeight - this.lastHeight;
                            document.getElementById('messages-window').scrollTop = top;
                            this.addListeners();
                        }, 200)

                    });
                })
            },
            setActiveChat(chat) {
                this.$store.commit('SET_ACTIVE_CHAT', chat);
                this.$store.commit('SET_MESSAGES', {messages: [], conversationId: null});
                this.messages = [];
                this.page = 1;
                this.hasNextPage = false;
                this.lastHeight = 0;
                this.loadingPreviousMessages = false;
                this.getConversationMessages(chat);
                this.activeChat.id = chat.id;

                this.activeChat.title = chat.title;
            },
            deselectActiveChat() {
                this.activeChat.id = null;
                this.activeChat.title = null;
                this.$store.commit('SET_MESSAGES', {messages: [], conversationId: null});
                this.messages = [];
                this.page = 1;
                this.hasNextPage = false;
                this.lastHeight = 0;
                this.loadingPreviousMessages = false;
            },
            initAPIs() {
                if (this.mode == 'customer') {
                    this.contactsApi = 'userGetContacts';
                    this.sendMessageAPI = 'userSendConversationMessage';
                    this.getConversationMessage = 'userGetConversationMessages';
                } else if (this.mode == 'counselor') {
                    this.contactsApi = 'counselorGetContacts';
                    this.sendMessageAPI = 'counselorSendConversationMessage';
                    this.getConversationMessage = 'counselorGetConversationMessages';
                } else if (this.mode == 'admin') {
                    this.contactsApi = 'adminGetContacts';
                    this.sendMessageAPI = 'adminSendConversationMessage';
                    this.getConversationMessage = 'adminGetConversationMessages';
                }
            },
            getContacts() {
                this.$store.dispatch(this.contactsApi).then((resp) => {
                    this.contacts = resp.data.contacts;
                })
            },
            handleSideChat() {
                this.sideChat = !this.sideChat;
            }
        },
        watch: {
            getScrollToBottom(newVal) {
                if (newVal) {
                    this.$nextTick().then(() => {
                        this.scrollToBottom();
                    });
                    this.$store.commit('SET_SCROLL_FALSE');
                }
            }
        }
    }
</script>

<style scoped>

</style>