import { render, staticRenderFns } from "./ChangeTzFormComponent.vue?vue&type=template&id=87c9e8ea&scoped=true&"
import script from "./ChangeTzFormComponent.vue?vue&type=script&lang=js&"
export * from "./ChangeTzFormComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "87c9e8ea",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
installComponents(component, {VAutocomplete,VCard,VCardText,VCardTitle,VForm})
