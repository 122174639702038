<template>
  <div>
    <v-card-text class="page-title d-block d-lg-none pt-2 pb-0 pr-0">{{ $route.meta.name }}</v-card-text>
    <v-breadcrumbs v-if="breadcrumb" class="pb-0 mb-0 pt-3 mr-0 pr-0" :items="items"></v-breadcrumbs>
    <v-container class="px-0 mx-0" :class="{'py-0 my-0':!breadcrumb}">
      <v-alert
          class="error white--text"
          border="left"
          elevation="0"
          v-if="getUserInfo.state.hasPendingPayments"
      >
        {{ $t('$vuetify.orderProductPage.hasPendingPayments') }}
      </v-alert>

      <v-alert class="purple-light px-1 top-alert" dense>
        <ul class=" white--text "   :class="$route.params.lang == 'en' ? 'text-left' : 'text-right'">
          <li class="ma-2 mr-4">
            {{ $t('$vuetify.orderProductPage.hints[0]') }}
          </li>
          <v-divider></v-divider>
          <li class="ma-2 mr-4">{{ $t('$vuetify.orderProductPage.hints[1]') }}</li>
        </ul>
      </v-alert>

      <v-tabs
          v-model="tab"
          v-if="breadcrumb"
      >
        <v-tab>
          {{ $t('$vuetify.orderProductPage.tabs[0]') }}
        </v-tab>
        <v-tab>
          {{ $t('$vuetify.orderProductPage.tabs[1]') }}
        </v-tab>
        <v-tab>
          {{ $t('$vuetify.orderProductPage.tabs[2]') }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <ProductGroupPage :type="'session'"></ProductGroupPage>
        </v-tab-item>
        <v-tab-item>
          <ProductGroupPage :type="'question'"></ProductGroupPage>
        </v-tab-item>
        <v-tab-item>
          <ProductGroupPage :type="'test'"></ProductGroupPage>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </div>
</template>

<script>
/*eslint-disable*/
import {mapGetters} from 'vuex';
import {EventBus} from "@/event-bus";
import {resolveCurrency} from "@/utilities";
import ProductGroupPage from './ProductGroupPage';

export default {
  name: 'pricing',
  components: {
    ProductGroupPage
  },
  props: {
    pushToCart: {
      default: true
    },
    showBreadCrumb: {
      default: true
    }
  },
  data() {
    return {
      tab: 0,
      activeTab: null,
      page: 1,
      total: 1,
      products: [],
      isHovered: false,
      breadcrumb: true,
      items: [
        {
          text: this.$t('$vuetify.dashboard'),
          disabled: false,
          to: `/${this.$route.params.lang}/panel/dashboard`,
        },
        {
          text: this.$t('$vuetify.ClientNavItem.buySession'),
          disabled: true,
          to: `/${this.$route.params.lang}/panel/sessions`,
        }
      ]
    }
  },
  computed: {

    ...mapGetters(['getCartType', 'getUserInfo']),
    productList() {
      return this.$store.getters.getProductList;
    }
  },
  created() {
    this.breadcrumb = this.showBreadCrumb !== "false";
    this.getProductList();
  },
  mounted() {

  },
  methods: {
    sendWebengageAddToCart(data) {
      window.webengage.track('add_to_cart', data);
    },
    getProductList() {
      EventBus.$emit('setLoading', true);
      let payload = {
        type: 'question'
      };
      this.$store.dispatch('searchProducts', {page: this.page, payload}).then((data) => {
        this.products = data.packages;
        this.total = data.pagination.total_pages;
      }).finally(() => {
        EventBus.$emit('setLoading', false);
        this.$emit('hasPackages');
      })
    },
    addToCart(item) {

      if (this.getCartType != null && item.currency !== this.getCartType) {

        this.$store.commit('setSnackbar', {
          show: true,
          type: 'error',
          message: 'ارز آیتم موجود در کارت شما با این محصول مطابقت ندارد'
        });
      } else {
        EventBus.$emit('setLoading', true);
        this.$store.dispatch('addToCart', item).then(() => {

          let product = {
            'name': item.title,
            'id': item.id,
            'price': item.price,
            'brand': 'Simiaroom',
            'category': 'Counseling',
            'quantity': 1
          };

          this.sendWebengageAddToCart(product);

          this.$gtm.trackEvent({
            'event': 'eec.addToCart',
            'ecommerce': {
              'add': {
                'products': [{
                  'name': item.title,
                  'id': item.id,
                  'price': item.price,
                  'brand': 'Simiaroom',
                  'category': 'Counseling',
                  // 'variant': 'Gray',
                  'quantity': 1
                }]
              }
            }
          });

          // this.$store.dispatch('getCart');
          this.$emit('closeWizard');
          this.$router.push({name: 'clientCart', params: {lang: 'fa'}});
          EventBus.$emit('setLoading', false);
        }).finally(() => {
          EventBus.$emit('setLoading', false);
        });
      }
    },
    resolveCurrency(input) {
      return resolveCurrency(input);
      // return input == 'rial' ? 'تومان' : 'دلار';
    }
  },
  filters: {

  },
  watch: {
    page() {
      this.getProductList();
    }
  }
}

</script>
